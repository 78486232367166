"use strict";
const Vue = require("vue");

window.onload = function () {
	new Vue({
	 "el": "#app",
	 "name": "admin",
	 "render": function (h) {
			return h(require("./components/GiftCard.vue"));
		}
	});
}
	